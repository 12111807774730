<template>
  <div class="course-profile-item">
    <router-link class="course-profile-pic"
                 :to="`/user/course/${course.slug}-${course.id}`"
                 :style="{'background-image': `url(${course.image})`}">
    </router-link>
    <div class="course-profile-content">
      <div class="cells">
        <div class="cell">
          <div class="course-profile-title-wrap">
            <div class="course-profile-pretitle"
                 v-if="course.themes && course.themes.length">
              {{ course.themes[0] }}
            </div>
            <router-link :to="`/user/course/${course.slug}-${course.id}`"
                         class="course-profile-title">
              {{ course.name }}
            </router-link>
          </div>
          <div class="marker-group">
            <div class="marker-item hollow" v-if="course.tag">{{ course.tag }}</div>
          </div>
        </div>
        <div class="cell">
          <div class="course-profile-offer" v-if="course.is_recommend">Рекомендовано в этом месяце</div>
          <div class="course-profile-offer"></div>
        </div>
        <div class="cell">
          <a class="button-color" @click="goToCourse(course.course_session)">
            К обучению
          </a>
          <div class="course-profile-percent"
               :class="{'active': course.is_completed}">
            {{ course.progress_percent }} %
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getLinkToCourse} from '@/core/consts/links';

export default {
  name: 'CourseCard',

  props: {
    course: Object,
  },

  data() {
    return {};
  },

  mounted() {
  },

  watch: {},

  methods: {
    routeToCourse(link) {
      this.$router.push({path: link,});
    },

    goToCourse(courseId) {
      window.open(
          getLinkToCourse(courseId),
          '_blank'
      );
    },
  },

  directives: {},

  components: {},
};
</script>

<style scoped>

</style>
