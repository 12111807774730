<template>
  <div>
    <div class="faq-group">
        <ProgramSlide v-for="program in programs"
                      :key="program.id"
                      :program="program">
        </ProgramSlide>
    </div>
    <div v-for="(survey, index) in surveys"
         :key="index"
         class="panel-item">
      <div :class="{'success': survey.progress === 100}" class="progress-wrap">
        <div class="progress-content">
          <h3 class="block-title">{{ survey.title }}</h3>
          <div class="progress-value">Общий прогресс: {{ survey.progress }}%</div>
        </div>
        <div class="progress-item">
          <div class="progress-line" :style="{width: survey.progress + '%'}"></div>
        </div>
      </div>
      <div>
        <template>
          <div class="course-profile-group">
            <course-card v-for="(course, index) in survey.courses"
                         :key="index"
                         :course="course"/>
          </div>
        </template>
      </div>
    </div>
    <div class="course-profile-group">
      <course-card v-for="(course) in courses"
                   :key="course.id"
                   :course="course"/>
    </div>
  </div>
</template>

<script>
import CourseCard from '@/components/profile/CourseCard';
import ProgramSlide from '@/components/ProgramSlide';

export default {
  name: 'ActiveCourses',

  props: {
    courses: Array,
    surveys: Array,
    programs: Array,
  },

  data() {
    return {};
  },

  mounted() {
  },

  watch: {},

  computed: {},

  methods: {},

  directives: {},

  components: {
    CourseCard,
    ProgramSlide,
  },
};
</script>

<style lang="scss" scoped>
</style>
