<template>
  <main class="body">

    <section class="section wow fadeIn">
      <div class="profile-block">
        <div class="content">
          <ul class="breadcrumbs">
            <li>
              <router-link to="/">Главная</router-link>
            </li>
            <li><span>Личный кабинет</span></li>
          </ul>
          <div class="profile-body">
            <profile-data/>
            <h1 class="page-title">Мои курсы</h1>
            <ul class="tabs">
              <li :class="{'active' : activeTab === 'ActiveCourses' }">
                <router-link to="/profile/active-courses/">Активные курсы</router-link>
              </li>
              <li :class="{'active' : activeTab === 'CompletedCourses' }">
                <router-link to="/profile/completed-courses/">Пройденные курсы</router-link>
              </li>
              <li :class="{'active' : activeTab === 'CompletedSurveys' }">
                <router-link to="/profile/completed-surveys/">Завершенные опросы</router-link>
              </li>
            </ul>
            <div v-show="activeTab !== 'CompletedSurveys'" class="filter-block">
              <div :class="{'active': isOpenedSearch}" class="filter-search-block">
                <search @show="isOpenedSearch = !isOpenedSearch"
                        @input="getCourses"
                        v-model="searchValue">
                </search>
                <single-select :title="'Сортировать'"
                               :options="[
                                   {title: 'По дате добавления', value: 'date_create'},
                                   {title: 'По алфавиту', value: 'name'}
                                 ]"
                               v-model="sortType"/>
                <single-select :title="'Уровень'"
                               :options="[
                                   {title: 'Все', value: ''},
                                   ...tags,
                                 ]"
                               v-model="filterTag"/>
              </div>
            </div>

            <active-courses
                v-show="activeTab === 'ActiveCourses'"
                :programs="filteredActivePrograms"
                :surveys="filteredActiveSurveys"
                :courses="activeCourses"
            />
            <completed-courses
                v-show="activeTab === 'CompletedCourses'"
                :courses="completedCourses"
                :programs="filteredCompletedPrograms"
            />
            <completed-surveys
                v-show="activeTab === 'CompletedSurveys'"
                :surveys="completedSurveys"
                :tags="tags"
            />
          </div>

        </div>
      </div>
    </section>

  </main>
</template>

<script>
import ProfileData from '@/components/profile/ProfileData';
import Search from '@/components/Search';
import SingleSelect from '@/components/SingleSelect';
import ActiveCourses from '@/components/profile/ActiveCourses';
import CompletedCourses from '@/components/profile/CompletedCourses';
import CompletedSurveys from '@/components/profile/CompletedSurveys';
import Profile from '@/api/profile';
import Teachbase from '@/api/teachbase';
import {sortArray} from '@/core/helpers/sortArray';

export default {
  name: 'Profile',

  data() {
    return {
      searchValue: '',
      sortType: null,
      filterTag: null,
      tags: null,
      activeTab: 'ActiveCourses',
      completedCourses: null,
      activeCourses: null,
      dataQuestionnaire: null,
      titleQuestionnaire: null,
      isOpenedSearch: false,
      activeSurveys: null,
      completedSurveys: null,
      activePrograms: null,
      completedPrograms: null,
    };
  },

  async created() {
    this.setActiveTab();
    await this.getTags();
    await this.getActivePrograms();
    await this.getCompletedPrograms();
    await this.getActiveSurveys();
    await this.getCompletedSurveys();
    await this.getActiveCourses();
    await this.getCompletedCourses();
  },

  computed: {
    filteredActiveSurveys() {
      if (this.activeSurveys?.length) {
        let surveys = this.activeSurveys;
        surveys = this.filterSurveyCourses(surveys, this.filterTag);
        surveys = this.sortSurveyCourses(surveys, this.sortType);
        surveys = this.filterSurveyBySearch(surveys, this.searchValue);
        return surveys;
      }
      return null;
    },

    filteredActivePrograms() {
      if (this.activePrograms?.length) {
        let program = this.activePrograms;
        program = this.filterSurveyCourses(program, this.filterTag);
        program = this.sortSurveyCourses(program, this.sortType);
        program = this.filterSurveyBySearch(program, this.searchValue);
        return program;
      }
      return null;
    },

    filteredCompletedPrograms() {
      if (this.completedPrograms?.length) {
        let program = this.completedPrograms;
        program = this.filterSurveyCourses(program, this.filterTag);
        program = this.sortSurveyCourses(program, this.sortType);
        program = this.filterSurveyBySearch(program, this.searchValue);
        return program;
      }
      return null;
    },
  },

  methods: {
    setActiveTab() {
      const routeParameter = this.$route.params.tabName;

      switch (routeParameter) {
        case 'active-courses':
          this.activeTab = 'ActiveCourses';
          break;
        case 'completed-courses':
          this.activeTab = 'CompletedCourses';
          break;
        case 'completed-surveys':
          this.activeTab = 'CompletedSurveys';
          break;
        default:
          this.activeTab = 'ActiveCourses';
      }
    },

    async getCourses() {
      await this.getActiveCourses();
      await this.getCompletedCourses();
    },

    async getActiveCourses() {
      const params = {
        is_completed: false,
        ordering: this.sortType,
        search: this.searchValue,
        tag: this.filterTag,
        themes: this.selectedThemes?.toString(),
      };
      const response = await Profile.getCourses(params);
      this.activeCourses = response?.data?.results;
    },

    async getCompletedCourses() {
      const params = {
        is_completed: true,
        ordering: this.sortType,
        search: this.searchValue,
        tag: this.filterTag,
        themes: this.selectedThemes?.toString(),
      };
      const response = await Profile.getCourses(params);
      this.completedCourses = response?.data?.results;
    },

    async getActivePrograms() {
      const params = {
        is_completed: false,
      };
      let response = await Profile.getAllPrograms(params);
      this.activePrograms = response.data?.results.filter(program => program?.courses?.length);
    },

    async getCompletedPrograms() {
      const params = {
        is_completed: true,
      };
      const response = await Profile.getAllPrograms(params);
      this.completedPrograms = response.data?.results.filter(program => program?.courses?.length);
    },

    async getTags() {
      const response = await Teachbase.getTags();
      this.tags = response.data.map(tag => {
        return {
          title: tag.replace('Уровень Подготовки: ', ''),
          value: tag,
        };
      });
    },

    async getActiveSurveys() {
      const params = {
        active: true,
      };
      const response = await Profile.getSurveys(params);
      this.activeSurveys = response.data;
    },

    async getCompletedSurveys() {
      const params = {
        active: false,
      };
      const response = await Profile.getSurveys(params);
      this.completedSurveys = response.data;
    },

    filterSurveyCourses(surveys, tag) {
      return surveys.map(survey => {
        let courses = survey.courses.filter(course => {
          if (tag) {
            return course.tag && course.tag === tag;
          }
          return course;
        });

        return {...survey, courses: courses,};
      }).filter(survey => {
        return survey?.courses?.length;
      });
    },

    sortSurveyCourses(surveys, sortType) {
      return surveys.courses = surveys.map(survey => {
        survey.courses = sortArray(survey.courses, {by: sortType, order: 'asc',});
        return survey;
      });
    },

    filterSurveyBySearch(surveys, searchValue) {
      return surveys.map(survey => {
        let courses = survey.courses.filter(course => {
          return course.name.toLowerCase().includes(searchValue.toLowerCase());
        });

        return {...survey, courses: courses,};
      }).filter(survey => {
        return survey?.courses?.length;
      });
    },
  },

  watch: {
    $route() {
      this.setActiveTab();
    },

    filterTag() {
      this.getCourses();
    },

    sortType() {
      this.getCourses();
    },
  },

  directives: {},

  components: {
    ProfileData,
    ActiveCourses,
    Search,
    SingleSelect,
    CompletedCourses,
    CompletedSurveys,
  },
};
</script>

