<template>
  <div>
    <div v-if="programs" class="faq-group">
      <ProgramSlide v-for="program in programs"
                    :key="program.id"
                    :program="program">
      </ProgramSlide>
    </div>
    <course-card v-for="(course, index) in courses"
                 :key="index"
                 :course="course"/>
  </div>
</template>

<script>

import CourseCard from '@/components/profile/CourseCard';
import ProgramSlide from '@/components/ProgramSlide';

export default {
  name: 'CompletedCourses',

  props: {
    courses: Array,
    programs: Array,
  },

  data() {
    return {};
  },

  mounted() {
  },

  watch: {},

  computed: {},

  methods: {},

  directives: {},

  components: {
    CourseCard,
    ProgramSlide,
  },
};
</script>

<style lang="scss" scoped>
</style>
