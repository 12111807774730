<template>
  <div v-if="surveys">
    <div class="faq-group"
         v-for="(survey, index) in surveys"
         :key="index">
      <questionnaire-slide :survey="survey" :tags="tags"/>
    </div>
  </div>
</template>

<script>
import QuestionnaireSlide from '@/components/profile/QuestionnaireSlide';

export default {
  name: 'CompletedSurveys',

  props: {
    surveys: Array,
    tags: Array,
  },

  data() {
    return {
      questionnaires: null,
    };
  },

  async created() {
  },

  mounted() {
  },

  watch: {},

  methods: {},

  directives: {},

  components: {
    QuestionnaireSlide,
  },
};
</script>

<style lang="scss" scoped>
</style>