<template>
  <div :class="{'active': isOpened}"
       class="faq-item toggle-block md">
    <div @click="isOpened = !isOpened;"
         class="faq-title toggle-button">
      {{ survey.title }}
    </div>
    <VueSlideToggle :open="isOpened">
      <div class="toggle-content" style="display: block">
        <div class="faq-content">
          <div :class="{'success': survey.progress === 100}" class="progress-wrap">
            <div class="progress-title">Общий прогресс: {{ survey.progress }} %</div>
            <div class="progress-item">
              <div class="progress-line" :style="{width: survey.progress + '%'}"></div>
            </div>
          </div>
          <div class="filter-block">
            <div :class="{'active': isOpenedSearch}" class="filter-search-block">
              <search @show="isShow" v-model="searchValue"></search>
              <single-select :title="'Сортировать'"
                             :options="[
                                   {title: 'По дате добавления', value: 'date_create'},
                                   {title: 'По алфавиту', value: 'name'}
                                 ]"
                             v-model="sortType"/>
              <single-select :title="'Уровень'"
                             :options="[
                                   {title: 'Все', value: ''},
                                   ...tags,
                                 ]"
                             v-model="filterTag"/>
            </div>
          </div>
          <div>
            <template>
              <div class="course-profile-group">
                <course-card v-for="(course, index) in filteredCourses"
                             :key="index"
                             :course="course"/>
              </div>
            </template>
          </div>
        </div>
      </div>
    </VueSlideToggle>
  </div>
</template>

<script>
import {VueSlideToggle} from 'vue-slide-toggle';
import CourseCard from '@/components/profile/CourseCard';
import Search from '@/components/Search';
import SingleSelect from '@/components/SingleSelect';
import {sortArray} from '@/core/helpers/sortArray';

export default {
  name: 'QuestionnaireSlide',

  props: {
    survey: Object,
    tags: Array,
  },

  data() {
    return {
      isOpened: false,
      searchValue: '',
      sortType: '',
      filterTag: '',
      isOpenedSearch: false,
    };
  },

  computed: {
    filteredCourses() {
      if (this.survey?.courses?.length) {
        let surveys = this.survey.courses;
        surveys = this.filterCourses(surveys, this.filterTag);
        surveys = this.sortCourses(surveys, this.sortType);
        surveys = this.filterBySearch(surveys, this.searchValue);
        return surveys;
      }
      return null;
    },
  },

  methods: {
    filterCourses(courses, tag) {
      return courses.filter(course => {
        if (tag) {
          return course.tag && course.tag === tag;
        }
        return course;
      });
    },

    sortCourses(courses, sortType) {
      return sortArray(courses, {by: sortType, order: 'asc',});
    },

    filterBySearch(courses, searchValue) {
      return courses.filter(course => {
        return course.name.toLowerCase().includes(searchValue.toLowerCase());
      });
    },

    isShow() {
      this.isOpenedSearch = !this.isOpenedSearch;
    },
  },

  components: {
    VueSlideToggle,
    CourseCard,
    Search,
    SingleSelect,
  },
};

</script>

<style scoped>
.faq-title {
  text-transform: capitalize;
}

.course-profile-group {
  margin-bottom: 0;
}
</style>