<template>
  <transition name="fade">
    <div v-if="user">
      <div class="profile-user-block">
        <img src="@/assets/img/avatar-empty.svg" alt="" class="profile-user-avatar">
        <div class="profile-user-content">
          <div class="profile-user-name">
            {{ user.last_name }} <br class="visible-mobile">
            {{ user.first_name }} <br class="visible-mobile">
            <!--          Вячеславович-->
          </div>
          <div v-if="user.job && Array.isArray(user.job)" class="profile-user-position">
            {{ user.job.join(', ') }}
          </div>
          <div class="profile-user-location">{{ user.city }}</div>
        </div>
      </div>
<!--      <div class="faq-item toggle-block lg"-->
<!--           :class="{'active' : isOpened}">-->
<!--        <div @click="isOpened = !isOpened;"-->
<!--             class="faq-title toggle-button">-->
<!--          Прогресс прохождения тем-->
<!--        </div>-->
<!--        <VueSlideToggle :open="isOpened">-->
<!--          <div class="toggle-content" style="display: block">-->
<!--            <div class="faq-content">-->
<!--              <div v-if="themes" class="progress-group">-->
<!--                <div v-for="(theme, index) in themes"-->
<!--                     :key="index"-->
<!--                     :class="{'success': theme.complete_courses === theme.total_courses}"-->
<!--                     class="progress-wrap">-->
<!--                  <div class="progress-content">-->
<!--                    <div class="progress-title">{{ theme.theme }}</div>-->
<!--                    <div class="progress-value">-->
<!--                      {{ theme.complete_courses }} из {{ theme.total_courses }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="progress-item">-->
<!--                    <div class="progress-line" :style="{width: theme.progress + '%'}"></div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </VueSlideToggle>-->
<!--      </div>-->
    </div>
  </transition>
</template>

<script>
import {VueSlideToggle} from 'vue-slide-toggle';
import {mapGetters} from 'vuex';

export default {
  name: 'ProfileData',

  props: {},

  data() {
    return {
      isOpened: false,
      themes: null,
    };
  },
  
  async created() {
    await this.getProgress();
  },

  mounted() {
  },

  computed: {
    ...mapGetters('user', ['user',]),
  },

  watch: {},

  methods: {
    async getProgress() {
      // const response = await Profile.getProgress();
      // let themes = response.data;
      // themes = themes.filter(theme => theme.progress);
      // themes = sortArray(themes, {by: 'progress', order: 'desc', });
      // this.themes = themes;
    },
  },

  directives: {},

  components: {
    VueSlideToggle,
  },
};
</script>

<style lang="scss" scoped>
</style>
